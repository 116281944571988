.modal::backdrop {
    background: #282c34;
    opacity: 0.8;
}

@supports (backdrop-filter: blur()) {
    .modal::backdrop {
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        background: none;
        opacity: 1;
    }
}

.modal {
    height: 50%;
    width: 50%;
    border: 1px solid var(--border-color);
    background: var(--main-bg-color);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    font-size: 1.2em;
    padding: 0;
    color: var(--main-fg-color);
}

.modal form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.modal form>* {
    width: 100%;
    box-sizing: border-box;
}

.modal header {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
}

.modal h1 {
    font-size: calc(10px + 1.5vmin);
    margin: 0;
}

.modal main {
    padding: 40px;
}

.modal footer {
    padding: 1em;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    column-gap: 2em;
}
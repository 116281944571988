.eventDetail {
    text-align: left;
    height: 100%;
    width: 100%;
}

.eventDetail header {
    text-align: center;
}

.eventDetail h1 {
    font-size: 1.25em;
    margin: 0;
    padding-block-start: 0.5rem;
}

.eventDetail h2 {
    font-size: 1.1em;
    margin: 0;
    margin-top: 1em;
    padding-inline-start: 5px;
    border-bottom: 1px solid var(--border-color);
}

.eventDetail table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    height: 100%;
    width: 100%;
}

.eventDetail tbody tr:nth-child(even) {
    background-color: var(--alt-bg-color);
}

.eventDetail :is(th, td) {
    padding: 2px 5px;
}

.eventDetail th {
    width: 12rem;
    border-right: 1px solid var(--border-color);
}

.eventDetail td {
    color: var(--alt-fg-color);
    padding-inline-start: 5px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
}

.pageList {
    list-style: none;
    margin: 0;
    padding: 5px;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  box-shadow: 0px 5px 25px 0 var(--container-border-color);
}

.header h1 {
  margin: 0.3em;
  font-size: inherit;
}

.menu {
  margin: 0;
  height: 100%;
  padding-inline-end: 0.3em;
  list-style-type: none;
  display: flex;
  align-items: flex-end;
}

.menu button {
  font-size: calc(10px + 0.5vmin);
}

.disclaimer {
  color: var(--alt-fg-color);
  font-size: 0.6em;
}

.container {
    overflow: auto;
    height: 100%;
    border: 1px solid var(--container-border-color);
    contain: strict;

    --ag-header-background-color: var(--table-header-color);
    --ag-data-color: var(--alt-fg-color);
    --ag-row-border-width: 1px;
    --ag-row-border-style: solid;
    --ag-row-border-color: var(--table-border-color);
    --ag-odd-row-background-color: var(--alt-bg-color);
    text-align: left;
}

.container [role='columnheader'] {
    font-weight: bold;
}

.firstCell {
    padding-inline-start: 5px;
}

.lastCell {
    padding-inline-end: 5px;
}

.interactionEvent {
    background-color: var(--interaction-color) !important;
}

.stepNumber {
    font-weight: bold;
}

.accessDenied {
    color: var(--access-denied-color) !important;
    font-weight: bold;
}

.adpEvent {
    background-color: var(--adp-color) !important;
}

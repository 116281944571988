.eventDetail {
    text-align: left;
    height: 100%;
    width: 100%;
}

.eventDetail header {
    text-align: center;
}

.eventDetail h1 {
    font-size: 1.25em;
    margin: 0;
    padding-block-start: 0.5rem;
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
}

.eventDetail h2 {
    font-size: 1.1em;
    margin: 0;
    margin-block-start: 5px;
    margin-block-end: 2px;
    padding-inline-start: 5px;
    width: calc(100% - 5px);
    overflow: hidden;
    overflow-wrap: break-word;
}

.eventDetail table {
    border-collapse: collapse;
    border-spacing: 0;
    height: 100%;
    width: 100%;
}

.eventDetail thead {
    background: var(--table-header-color);
    border-bottom: 1px solid var(--border-color);
}

.eventDetail tbody > tr:nth-child(even) {
    background-color: var(--alt-bg-color);
}
.eventDetail tbody > tr:nth-child(odd) {
    background-color: var(--main-bg-color);
}

.eventDetail :where(th, td) {
    padding: 2px 5px;
    vertical-align: text-top;
}

.eventDetail td {
    color: var(--alt-fg-color);
    padding-inline-start: 5px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
}

.eventDetail tr th:first-child {
    border-right: 1px solid var(--border-color);
    width: 12rem;
    white-space: nowrap;
}

.eventDetail tr td:last-child {
    width: 99%;
}

td.collapsePadding {
    padding: 0;
    border: 1px solid var(--border-color);
}

.container {
    overflow: auto;
    height: 100%;
    width: 100%;
    border: 1px solid var(--container-border-color);
    contain: strict;
}

.container table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    height: 100%;
    width: 100%;
}

.container :is(td, th) {
    text-align: left;
}

.container tr :is(td,th):first-child {
    padding-inline-start: 5px;
}

.container tr :is(td,th):last-child {
    padding-inline-end: 5px;
}

.container thead {
    background: var(--table-header-color);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
    z-index: 1;
}

.container thead input {
    box-sizing: border-box;
    width: 100%;
}

.headerLabel {
    -webkit-user-select: none;
    user-select: none;
    height: 100%;
}

.sortableHeader {
    cursor: pointer;
}

.container tbody {
    color: var(--alt-fg-color);
}

.container tbody tr {
    border-bottom: 1px solid var(--table-border-color);
}

.container tbody td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.even {
    background-color: var(--alt-bg-color);
}

.odd {
    background-color: inherit;
}

.interactionEvent {
    background-color: var(--interaction-color);
}

.stepNumber {
    font-weight: bold;
}

.accessDenied {
    color: var(--access-denied-color);
    font-weight: bold;
}

.adpEvent {
    background-color: var(--adp-color);
}

.traceViewer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.toolbar {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.15rem;
    gap: 1rem;
}

.App {
  height: 100svh;
  width: 100svw;
  display: flex;
  flex-direction: column;
}

.App main {
  flex: 1;
  padding: 15px 5px 5px 5px;
}

:root {
  color-scheme: dark light;

  --dark-main-fg-color: white;
  --dark-alt-fg-color: #dbdbdb;
  --dark-main-bg-color: #282c34;
  --dark-alt-bg-color: #474d58;
  --dark-border-color: white;
  --dark-table-header-color: #586174;
  --dark-table-border-color: #8b8585;
  --dark-access-denied-color: #ffa7a7;
  --dark-interaction-color: #5a554e;
  --dark-container-border-color: #586174;
  --dark-error-color: red;
  --dark-dropzone-background-color: rgba(255, 255, 255, 15%);
  --dark-focus-border-color: #2196f3;
  --dark-adp-color: #596241;

  --light-main-fg-color: #282c34;
  --light-alt-fg-color: #353535;
  --light-main-bg-color: white;
  --light-alt-bg-color: #dbdbdb;
  --light-border-color: #282c34;
  --light-table-header-color: #dbdbdb;
  --light-table-border-color: #dbdbdb;
  --light-access-denied-color: #b32b2b;
  --light-interaction-color: #e8ddce;
  --light-container-border-color: #dbdbdb;
  --light-error-color: red;
  --light-dropzone-background-color: rgba(0, 0, 0, 15%);
  --light-focus-border-color: #2196f3;
  --light-adp-color: #D9F78B;
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-fg-color: var(--dark-main-fg-color);
    --alt-fg-color: var(--dark-alt-fg-color);
    --main-bg-color: var(--dark-main-bg-color);
    --alt-bg-color: var(--dark-alt-bg-color);
    --border-color: var(--dark-border-color);
    --table-header-color: var(--dark-table-header-color);
    --table-border-color: var(--dark-table-border-color);
    --access-denied-color: Var(--dark-access-denied-color);
    --interaction-color: var(--dark-interaction-color);
    --container-border-color: var(--dark-container-border-color);
    --error-color: var(--dark-error-color);
    --dropzone-background-color: var(--dark-dropzone-background-color);
    --focus-border-color: var(--dark-focus-border-color);
    --adp-color: var(--dark-adp-color);
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --main-fg-color: var(--light-main-fg-color);
    --alt-fg-color: var(--light-alt-fg-color);
    --main-bg-color: var(--light-main-bg-color);
    --alt-bg-color: var(--light-alt-bg-color);
    --border-color: var(--light-border-color);
    --table-header-color: var(--light-table-header-color);
    --table-border-color: var(--light-table-border-color);
    --access-denied-color: Var(--light-access-denied-color);
    --interaction-color: var(--light-interaction-color);
    --container-border-color: var(--light-container-border-color);
    --error-color: var(--light-error-color);
    --dropzone-background-color: var(--light-dropzone-background-color);
    --focus-border-color: var(--light-focus-border-color);
    --adp-color: var(--light-adp-color);
  }
}

.dark {
  --main-fg-color: var(--dark-main-fg-color);
  --alt-fg-color: var(--dark-alt-fg-color);
  --main-bg-color: var(--dark-main-bg-color);
  --alt-bg-color: var(--dark-alt-bg-color);
  --border-color: var(--dark-border-color);
  --table-header-color: var(--dark-table-header-color);
  --table-border-color: var(--dark-table-border-color);
  --access-denied-color: Var(--dark-access-denied-color);
  --interaction-color: var(--dark-interaction-color);
  --container-border-color: var(--dark-container-border-color);
  --error-color: var(--dark-error-color);
  --dropzone-background-color: var(--dark-dropzone-background-color);
  --focus-border-color: var(--dark-focus-border-color);
  --adp-color: var(--dark-adp-color);
}

.light {
  --main-fg-color: var(--light-main-fg-color);
  --alt-fg-color: var(--light-alt-fg-color);
  --main-bg-color: var(--light-main-bg-color);
  --alt-bg-color: var(--light-alt-bg-color);
  --border-color: var(--light-border-color);
  --table-header-color: var(--light-table-header-color);
  --table-border-color: var(--light-table-border-color);
  --access-denied-color: Var(--light-access-denied-color);
  --interaction-color: var(--light-interaction-color);
  --container-border-color: var(--light-container-border-color);
  --error-color: var(--light-error-color);
  --dropzone-background-color: var(--light-dropzone-background-color);
  --focus-border-color: var(--light-focus-border-color);
  --adp-color: var(--light-adp-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--main-bg-color);
  color: var(--main-fg-color);
  text-align: center;
}

body,
#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.outerContainer {
    height: 100%;
    width: 100%;
}

.container {
    overflow: auto;
    height: calc(100% - 1.5rem);
    width: 100%;
    border: 1px solid var(--container-border-color);
    contain: strict;
}

.container table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;    
    width: 100%;
}

.paging {
    height: 1.5rem;
    width: 100%;
    background: var(--main-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.paging button {
    margin: 0 0.1rem;
}

.container :is(td, th) {
    text-align: left;
}

.container tr :first-child {
    padding-inline-start: 5px;
}

.container tr :last-child {
    padding-inline-end: 5px;
}

.container thead {
    background: var(--table-header-color);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
    z-index: 1;
}

.container tbody {
    color: var(--alt-fg-color);
}

.container tbody tr {
    border-bottom: 1px solid var(--table-border-color);
}

.container tbody td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.even {
    background-color: var(--alt-bg-color);
}

.odd {
    background-color: inherit;
}

.interactionEvent {
    background-color: var(--interaction-color);
}

.stepNumber {
    font-weight: bold;
}

.accessDenied {
    color: var(--access-denied-color);
    font-weight: bold;
}

.adpEvent {
    background-color: var(--adp-color);
}

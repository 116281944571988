.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  margin: 10vh 15vw;
  height: 50vh;
}

.error {
  color: var(--error-color);
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed var(--alt-fg-color);
  border-radius: 5px;
  background-color: var(--dropzone-background-color);
  color: var(--alt-fg-color);
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: var(--focus-border-color);
}
